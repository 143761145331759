import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useParams } from "react-router-dom";
import Parser from 'html-react-parser';

export default function List() {

    const [category, setCategory] = useState([])

    const instance = axios.create({
        baseURL: process.env.REACT_APP_HOST + '/api/v1',
        timeout: 1000,
        headers: {'Authorization': 'Bearer hgAsd4jAsdnhgsderfgSdgrf56yhSa2ehdfrtol9AsefgsaSergthjN2seA45'}
    });

    const params = useParams();

    useEffect(()=>{
        fetchCategory() 
    },[])

    const fetchCategory = async () => {
        await instance.get('/category/'+params.id).then(({data})=>{
            setCategory(data);
        })
    }

    return (
      <div className="container">
          <div className="row">
            <div className="col-12">
                <div className="card card-body">
                    <h1>{category.title}</h1>
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0 text-center">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    category.products && category.products.length > 0 && (
                                        category.products.map((row, key)=>(
                                            <tr key={key}>
                                                <td>{row.title}</td>
                                                <td>{ Parser(row.description) }</td>
                                                <td><img width="100" src={row.image} /></td>
                                                <td>{row.price}</td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
      </div>
    )
}