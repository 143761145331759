import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";

import { BrowserRouter as Router , Routes, Route, Link } from "react-router-dom";

import ProductsList from "./components/products/list.component";
import CategoryList from "./components/category/list.component";
import CategoriesList from "./components/categories/list.component";

function App() {
  return (<Router>
    <Navbar bg="primary">
      <Container>
        <Link to={"/"} className="navbar-brand text-white">
          Basic Crud App
        </Link>
        <Link to={"/categories"} className="navbar-brand text-white">
          Categories
        </Link>
        <Link to={"/products"} className="navbar-brand text-white">
          Prodotti
        </Link>
      </Container>
    </Navbar>

    <Container className="mt-5">
      <Row>
        <Col md={12}>
          <Routes>
            <Route exact path='/' element={<CategoriesList />} />
            <Route exact path='/categories' element={<CategoriesList />} />
            <Route exact path='/products' element={<ProductsList />} />
            <Route path="/category/*">
              <Route path=":id" element={<CategoryList />}></Route>
            </Route>
          </Routes>
        </Col>
      </Row>
    </Container>
  </Router>);
}

export default App;